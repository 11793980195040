import React from "react";
// import Footer from "../Footer/Footer";
// import GenNav from "../GenNav";
// import Header from '../Header/Header'
// import Nav from '../Nav'
// import { MdPadding } from 'react-icons/md'

const InitiativesPrograms = () => {
	return (
		<section style={{ background: "#f8ffff" }}>
			{/* <GenNav /> */}
			<br />

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					padding: "20px",
					marginBlock: "40px",
				}}>
				<di style={{ paddingBlock: "20px" }}>
					{" "}
					<h1 style={{ paddingBlock: "10px" }}>INITIATIVES AND PROGRAMS</h1>
					<p>
						Teens Literacy Foundation runs on a pre-planned annual calendar from
						January every year till December. There are fixed initiatives that
						run yearly with modifications each year, and there are occasional
						events that the foundation carries out based on needs available to
						be met. Below are our fixed initiatives and programs;
					</p>{" "}
				</di>

				<ul
					style={{
						listStyle: "none",
						display: "flex",
						flexDirection: "column",
						gap: "10px",
					}}>
					<li>
						1.{" "}
						<b style={{ paddingBlock: "10px", fontSize: "26px" }}>
							{" "}
							Project 1000Saviours:{" "}
						</b>
						<p style={{ fontSize: "14px", paddingBlock: "10px" }}>
							Often, many have asked questions on how to avert the societal
							problems the world, especially Nigeria, is plagued with. As an
							organization, we have discovered that the core of the issues is
							the leadership problem. Leadership does not refer to being in
							government houses but coordinating, instructing, reasoning, and
							executing proper thoughts and abilities. This program targets
							pupils and teenagers and aims to recruit as many as possible to
							our leadership academy, where teaching and mentorship are.
							Adequate exposure will also be provided to this young generation
							on the tenets of leadership, what leadership is, and what
							leadership is not, to foster nation-building and solve crises
							across every aspect that leadership spreads.
						</p>{" "}
					</li>
					<li>
						2.{" "}
						<b style={{ paddingBlock: "10px", fontSize: "26px" }}>
							{" "}
							Teach a Subject/Topic Campaign
						</b>
						<p style={{ fontSize: "14px", paddingBlock: "10px" }}>
							According to Sustainable Development Goal 4, our foundation aims
							to ensure inclusive and equitable education and promote lifelong
							learning opportunities for all. We achieve this through various
							projects across schools, within and without the four walls of the
							classroom. However, for education to be inclusive and sustainable,
							parents, teachers, administrators, and students must receive
							adequate support in every way possible. Therefore, this project
							aims to achieve three purposes: <br />
							<div>
								a. Provide external hands to schools and teachers in handling
								specific subject(s) or topic(s) in the subject taught in
								schools.
								<br />
								b. Temporarily relieve teacher(s) for the period(s) of the
								subject, so they could recuperate, recharge and become more
								efficient in their roles and duties. <br /> br c. Expose our
								volunteers to educational needs to achieve SDG 4 and how to
								contribute locally and globally to this sustainable goal. <br />
								Our volunteers are adequately up to the task of imparting
								knowledge in their preferred subject of interest, even though
								not all have formal teachers’ training.{" "}
							</div>
						</p>{" "}
					</li>
					<li>
						3.{" "}
						<b style={{ paddingBlock: "10px", fontSize: "26px" }}> SACRAF: </b>
						<p style={{ fontSize: "14px", paddingBlock: "10px" }}>
							{" "}
							Students Arts and Crafts Fest SACRAF is an annual students’
							festival set up by the Teens Literacy Foundation to encourage
							young minds gifted in Arts and Crafts. The festival enjoys
							partnerships from corporate bodies and individuals, parastatals,
							and various government ministries. Students would be encouraged to
							come up with original work, and a grand event marks the end of the
							festival where exceptional Arts work would be recognized,
							appreciated, and rewarded.
						</p>{" "}
					</li>
					<li>
						{" "}
						4. <b> The Next Innovator </b>
						<p style={{ fontSize: "14px", paddingBlock: "10px" }}>
							This science-driven social impact project focuses on developing
							critical thinking and problem-solving skills in pupils and
							students. This project also embraces SDG 9, which focuses on
							building resilient infrastructure, promoting inclusive and
							sustainable industrialization, and fostering innovations. The role
							of science and technology in solving myriads of man’s problems
							cannot be overemphasized. However, there is still a long way to go
							regarding industrialization and innovation, especially in Nigeria
							and Africa. This project will help to broaden the mind of our
							young scientists and innovators in secondary school. It will
							include support of economic development and human beings,
							increased insight and access to small-scale industrial innovation,
							and taking action according to available capabilities.
						</p>{" "}
					</li>
					<li>
						5. <b style={{ paddingBlock: "10px", fontSize: "26px" }}>TWALP: </b>{" "}
						<p style={{ fontSize: "14px", paddingBlock: "10px" }}>
							{" "}
							The Woman, Advocacy, Leadership, and Policy This initiative
							focuses on achieving gender equality and empowers all women and
							girls in line with SDG 5. This program will encourage the
							participation of girls (who would go on to become women) in
							leadership and challenge them to get involved in policy-making and
							various advocacy opportunities. The project also aims at providing
							relevant support for the girl-child in fulfilling her dreams.
						</p>
					</li>
					<li>
						6. <b style={{ paddingBlock: "10px", fontSize: "26px" }}>TGH:</b>{" "}
						<p style={{ fontSize: "14px", paddingBlock: "10px" }}>
							{" "}
							Teenagers Global Health Our organization conducts research
							projects, collates primary data from community participants, and
							executes projects that directly address health promotion through
							preventing and reducing Non-Communicable Diseases (NCDs). Our
							campaigns involve reducing the prevalence of sedentary living,
							substance addiction-related diseases, and other lifestyle health
							challenges in our communities of interest. In addition, we have
							developed self-help programs, rehabilitation support, and
							interventions to address these issues. Our flagship project
							involved educating high school students and other young people in
							the communities of engagement on mental wellness and handling
							suicidal tendencies. Our organization aims to solve problems
							through literacy and education on a wide range of life, social and
							wellness issues faced by teenagers and youth through integrative
							practical life experiences within the community rather than
							theoretical dogma.
						</p>
					</li>
				</ul>
			</div>
			{/* <Footer /> */}
		</section>
	);
};

export default InitiativesPrograms;
