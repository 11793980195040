import React from "react";
// import logo from "../img/tlf.jpeg";
import "./header.css";
import styled from "styled-components";

const Header = () => {
	// const [click, setClick] = useState(false);
	// const [mobile, setMobile] = useState(true);
	// const [Navbar, setNavbar] = useState(false);
	// const changeBackground = () => {
	// 	if (window.scrollY >= 80) {
	// 		setNavbar(true);
	// 	} else {
	// 		setNavbar(false);
	// 	}
	// };
	// const toggleNavbar = () => {
	// 	setClick(!click);
	// 	setMobile(!mobile);
	// 	if (!click) {

	// 		document.body.style.overflow = "hidden";
	// 	} else {

	// 		document.body.style.overflow = "auto";
	// 	}
	// };

	// useEffect(() => {
	// 	changeBackground();

	// 	window.addEventListener("scroll", changeBackground);
	// });
	return (
		<HeadWrapper>
			<header></header>
		</HeadWrapper>
	);
};

const HeadWrapper = styled.div`
	.mobile-menu {
		position: relative;
		//  border 0.1px solid #f18c8c;
		// display:flex;
	}
	.mb_drp-dwn {
		position: fixed;
		left: -2%;
		top: 5%;
		// background:inset 0 0 0 2000px rgba(44, 43, 43, 0.42);
		background: inset 0 0 0 2000px #8a79796b;
		border-radius: 8px;
		padding: 4px 6px;
	}
`;
export default Header;
