import React from "react";
// import image from "../img/Group 5.svg";
import image1  from '../img/pstoutrch.jpg'
import image3 from "../img/ESpics-23.jpg";
import image2 from "../img/outrch.jpg";
// import image3 from "../img/ESpics-20.jpg";
import image4 from "../img/outreach.jpg";
import image5 from '../img/schoutreachjpg.jpg'
// import image5 from "../img/ESpics-16.jpg";
import "./image.css";

function Image() {
  return (
    <section className="image-pic" >
      <article className="courses">
        <div className="course-description">
          <h1>Our Past Events</h1>
          {/* <p>
            Here at TMAcademy, you learn with comfort. You learn in a serene and
            friendly environment with well equiped amenities at your disposal.
          </p> */}
        </div>

        <section id="content">
          <div class="one" data-aos="zoom-in" data-aos-duration="2000">
            {" "}
            <img src={image1} alt="" />{" "}
          </div>
          <div class="two" data-aos="zoom-in" data-aos-duration="2000">
            {" "}
            <img src={image3} alt="" />
          </div>
          <div class="three" data-aos="zoom-in" data-aos-duration="2000">
            {" "}
            <img src={image5} classname="thre" style={{height:''}} alt="" />
          </div>
          <div class="four" data-aos="zoom-in" data-aos-duration="2000">
            {" "}
            <img src={image2} alt="" />
          </div>
          <div class="five" data-aos="zoom-in" data-aos-duration="2000">
            {" "}
            <img src={image4} alt="" />
          </div>
        </section>

        {/* <div>
          <img src={image} className="student-image" alt="" />
        </div> */}
      </article>
    </section>
  );
}

export default Image;
