import React from "react";
import { BrowserRouter as Router,Routes, Route, } from "react-router-dom";
import "./App.css";
// import Homepage from "./Homepage";
// import Volunteer from "./Pages/Volunteer";
// import Home from "./Homepage";
import About from "./About/About";
import Donate from "./Pages/Donate";
import Events from "./Pages/VolunteerPage";
import Homepage from "./Homepage";
// import  AboutPage from "./About/AboutPage";
import VolunteerPage from "./Pages/VolunteerPage";
import InitiativesPrograms from "./Pages/InitiativesPrograms";
import Courses from "./Pages/Courses";
import Contact from "./Contact";
import Shared from "./Outlet/Shared";
import AboutPage from "./About/AboutUs";
// import Shared from "./Outlet/Shared";

function App() {
  return (
    <div>
   <Router>
        <Routes>
          <Route path="/" element={<Shared/>}>
            <Route index element={<Homepage/>}/>
          <Route path="volunteer" element={<VolunteerPage />} />
          <Route path="donate" element={<Donate />} />
          <Route path="events" element={<Events />} />
          <Route path="contact" element={<Contact/>}/>
          <Route path="about" element={<About/>} />
          <Route path='aboutpage' element={<AboutPage/>}/>
<Route path="initiatives" element={<InitiativesPrograms/>}/>  
     {/* <Route path="aboutUs" element={<AboutPage/>}/> */}
        </Route>
        <Route path="courses" element={<Courses/>}/>
        </Routes>
      </Router>
    </div>
  );
}
export default App;
