import React, { useState, useRef } from "react";
import logo from "../img/tlf.jpeg";
import instagram from "../img/ig.png";
import tiktok from "../img/tiktok.png";
import twitter from "../img/tt.png";
import "./footer.css";
import { FaBlogger } from "react-icons/fa";
import { BiPhoneCall } from "react-icons/bi";
// import {MdPlayArrow} from "react-icons/md"
import { AiOutlineMail } from "react-icons/ai";
import { MdVolunteerActivism } from "react-icons/md";
import { BiDonateHeart } from "react-icons/bi";
import { AiOutlineTwitter } from "react-icons/ai";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import {AiFillNotification} from "react-icons/ai"
import { Link } from "react-router-dom";
const Footer = () => {
	const date = new Date().getFullYear();
	console.log(date);
	const [email, setEmail] = useState("");
	const form = useRef();

	const sendEmail = (e) => {
		if (email === "") {
			return;
		}
		e.preventDefault();
		//  details();
		//  setisLoading(false);
		emailjs
			.sendForm(
				"service_jcwsreh",
				"template_k3djsmq",
				form.current,
				"yM3x5AzUSRJqdyURM"
			)
			.then(
				(result) => {
					// setisLoading(false);
					console.log(result.text);
					toast.success("you have successfully Subscribed");
				},
				(error) => {
					console.log(error.text);
					toast.error("Subscribe Unsuccessful");
					//  setisLoading(false)
				}
			);
	};
	return (
		<section className="foot">
			<article className="top-footer">
				<div className="tf-content">
					<p>Get in touch</p>
					<span className="git">
						<BiPhoneCall className="icon" />
						<strong></strong>+234 903 363 8826
					</span>
					<span className="git">
						<AiOutlineMail className="icon" />{" "}
						<a href="mailto:teensliteracyfoundation@gmail.com">
							{" "}
							teensliteracyfoundation@gmail.com{" "}
						</a>
					</span>
					{/* <span className="git"><BiPhoneCall className="icon"/> +2345678889</span> */}
				</div>
				<div className="tf-content">
					<p>Quick links</p>
					<Link to="/contact" className="q-links">
						<BiPhoneCall className="icon" />
						Contact us
					</Link>
					<Link to="/volunteer" className="q-links">
						<MdVolunteerActivism className="icon" />
						Volunteer
					</Link>
					<Link to="/donate" className="q-links">
						<BiDonateHeart className="icon" />
						Donate
					</Link>
					<a
						className="q-links"
						href="https://teensliteracyfoundation.blogspot.com/">
						<FaBlogger className="icon" />
						Blogs
					</a>
					{/* <a className="q-links" href="http://"><AiFillNotification className="icon"/>Announcements</a> */}
					<a
						className="q-links"
						href="https://twitter.com/TlfCentral?s=09"
						target="_blank"
						rel="noopener noreferrer">
						<AiOutlineTwitter className="icon" />
						tweets
					</a>
				</div>
				{/* <div  className="tf-content">
          <p>Recent Post</p>
          <a href="/" className="post"><MdPlayArrow  className="icon"/> <li className="post-content"><span className="post-title">Title</span><span className="post-desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum, quisquam.</span></li></a >
          <a href="/" className="post"><MdPlayArrow className="icon"/> <li className="post-content"><span className="post-title">Title</span><span className="post-desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum, quisquam.</span></li></a >
          <a href="/" className="post"><MdPlayArrow className="icon"/> <li className="post-content"><span className="post-title">Title</span><span className="post-desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum, quisquam.</span></li></a >
          </div> */}
				<div className="tf-content">
					<p>Join a Newsletter</p>
					<form ref={form} onSubmit={sendEmail} className="form-content">
						<label for="email">
							Email Address<sup>*</sup>
						</label>
						<input
							className="tf_input"
							required
							placeholder="Enter your Mail"
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							name="user_email"
						/>
						<button className="tf-button" type="submit" value="Send">
							Subscribe
						</button>
					</form>
				</div>
			</article>
			<div className="footer">
				<div className="left">
					<Link to="/">
						<img src={logo} className="logos" alt="" />
					</Link>
				</div>
				<div className="middle">
					{" "}
					<li>{/* <a href="#register">Support</a> */}</li>
					<li>
						{/* <a href="#register" className="tc">Terms & Conditions</a> */}
					</li>
				</div>
				<div className="right">
					<a href="/" className="right-a">
						<a
							href="https://instagram.com/teensliteracyfoundation?igshid=YmMyMTA2M2Y="
							target="_blank"
							rel="noopener noreferrer">
							<img src={instagram} alt="" />{" "}
						</a>
						<a
							href="https://www.tiktok.com/@tlfcentral?is_from_webapp=1&sender_device=pc"
							target="_blank"
							rel="noopener noreferrer">
							<img src={tiktok} className="tk" alt="" />{" "}
						</a>
						<a
							href="https://twitter.com/TlfCentral?s=09"
							target="_blank"
							rel="noopener noreferrer">
							{" "}
							<img src={twitter} alt="" />
						</a>
					</a>
					<li className="at">@{date} tlf,net</li>
				</div>
			</div>
			<ToastContainer
				position="top-center"
				autoClose={100}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
		</section>
	);
};

export default Footer;
