import React from "react";
import "./about.css";
// import Footer from "../Footer/Footer";

 const AboutPage = () => {
  return (
    <div className="about-page">
      <section className="aboutUs-img"></section>
      <article className="about-text">
        <h1 className="abtpage-title">About us</h1>
        <p>
          Teen Literacy Foundation (TLF) educates teenagers and youths across
          vital areas of life. Our organization provides a platform for
          awareness and nurturing young minds with every tool needed to become
          global leaders and change-makers within and outside their immediate
          environment. We advocate literacy, which by our definition, is not
          limited to the ability to read and write. Still, it includes awareness
          and the ability to make the right choices, accurate information
          voluntarily, and guided positive actions on various issues. We aim to
          take education beyond the walls of the classroom. We do this through a
          holistic approach to providing education, guidance, academic
          aptitudes, skills, and an inclusive and sustainable environment for
          target audiences. We run with four primary aims: 1. To take education
          beyond the classroom walls 2. To bring pupils and students to the
          discovery of purpose 3. To train pupils and students in critical
          thinking skill and problem-solving skills 4. To equip the target
          audience with the right tools and mindset to live a purposeful life
          Our target audiences are Primary school pupils, Secondary school
          students, Orphans and Orphanages, Drop-outs, and internally displaced
          infants and teenagers. To meet up with the aims of the organization,
          we embrace the 17 SDGs towards our nation building challenges and
          roles. However, our primary emphasis is on SDG 3 (Healthy lives and
          wellness for all ages), SDG 4 (Inclusive and Equitable quality
          education and lifelong learning opportunities for all), and SDG 5
          (Gender equality and empowerment of all women and girls). As an
          organization, our core values revolve around dedication, faithfulness,
          service, and skillfulness. We are people-oriented, project-centered,
          and a crop of achievers.
        </p>
      </article>
      <article className="abt-page">
        <h4> Our Vision </h4>
        <p>
         Our vision at the Teens Literacy Foundation (TLF) is to raise an army of leaders across all spheres of influence through the tool of holistic education. By implication, this entails the education of the mind (the seat of reasoning and intelligence), the soul (the center of consciousness and awareness), and the body (the home for everything called human)
        </p>
      </article>
      <article className="abt-page">
        <h4> Our Mission </h4>
        <p>
       Our mission is to contribute to nation building through our programs, partnerships and collaborations with individuals and similar corporate organizations, both local and international. We aim to take education beyond the walls of the classroom. We do this through a holistic approach to providing education, guidance, academic aptitudes, skills, and an inclusive and sustainable environment for target audiences. 
        </p>
      </article>
      
      {/* <Footer /> */}
    </div>
  );
};


export default AboutPage;