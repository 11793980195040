import React from "react";
// import Nav from '../Nav'
import Anime from "../Navbar/AnimatedNav";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
// import AnnouncementBanner from "../Announcement/AnnouncementBar";
// import AnnouncementBanner from "../Announcement/AnnouncementBar";
// import AnnouncementBanner from "../Announcement/AnnouncementBar";

const Shared = () => {
	return (
		<div style={{ overflowX: "hidden" }}>
			<Anime />
			<Outlet />
			<Footer />
		</div>
	);
};

export default Shared;
