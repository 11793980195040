import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./event.css";
// import Footer from "../Footer"
import styled from "styled-components";
import vol from "../img/vll.jpg";
// import v1 from "../img/volunteer.jpg"
// import v2 from "../img/vl.jpg"
// import v3 from "../img/donations.jpg"
import { Link } from "react-router-dom";
// import volhead from '../img/vol.jpg'
import { BiDonateHeart } from "react-icons/bi";
import { SiGooglescholar } from "react-icons/si";
import { FcCollaboration } from "react-icons/fc";
import { FcDonate } from "react-icons/fc";
import vimage from "../img/volun.jpg";
import "./volunteer.css";
// import Footer from "../Footer/Footer";
import Donate from "./Donate";
// import GenNav from "../GenNav";
// import GenNav from '../GenNav'
// import VolunteerForm from "../VolunteerForm";

const VolunteerPage = () => {
	// const [gform,setGForm]=useState(false)
	const [donations, setDonations] = useState(false);
	const [email, setEmail] = useState("");
	const form = useRef();

	const sendEmail = (e) => {
		if (email === "") {
			return;
		}
		e.preventDefault();
		//  details();
		//  setisLoading(false);
		emailjs
			.sendForm(
				"service_jcwsreh",
				"template_k3djsmq",
				form.current,
				"yM3x5AzUSRJqdyURM"
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
	};
	return (
		<>
			{/* <GenNav /> */}

			<EventContainer>
				<article className="vol-head">
					<div className="vol-text">
						<span className="v-call">Become a Member</span>
						{/* <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas aliquid architecto cum sunt aut explicabo ullam esse repellendus at praesentium optio ducimus, distinctio officia, dolorem provident commodi, aperiam earum? Doloremque.</p> */}
						<form ref={form} onSubmit={sendEmail}>
							<input
								placeholder="Enter your Mail"
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								name="user_email"
							/>
							<a
								href="https://forms.gle/k3HJeu9jWtQmoTd69"
								className="sn-button"
								type="submit"
								value="Send">
								SignUp
							</a>
						</form>
					</div>
				</article>
				{/* {gform && <VolunteerForm/>} */}
				<section className="v-desc_section">
					{/*  */}
					<section className="vln-container">
						<article className="vol-middle">
							<div className="frst">
								<h4> Join Us On Our Mission!</h4>
								<div className="divider"></div>
								<p>
									We are on a mission to build a system of men who will be
									change-makers, policy-makers, pathfinders, and thorough-bred
									functionaries in every domain of influence. We need you to
									achieve this. There are several ways you can help our course,
									kindly check below and take action.
								</p>
							</div>

							<article className="vol-section">
								{/*  */}
								<section className="ctx">
									<FcCollaboration
										className="ct-icon"
										style={{ fontSize: "40px" }}
									/>
									<div className="txt">
										<span className="txt-1">Become a volunteer</span>
										<span className="txt-2">
											Join a team of exciting young men and women who are
											providing education beyond the walls of the classroom to
											our target audience. You can find our projects{" "}
											<Link to="/initiatives">here</Link>. Growth is an
											opportunity to be fruitful. Process is an opportunity to
											be consumable. The times we are in for those who follow
											trends show that the future of personal economy is in
											investments. Invest your time, invest your resources! We
											are products of what we give ourselves to. The future is
											here with us already. 
										</span>
									</div>
								</section>
								{/*  */}
								<section className="ctx">
									{" "}
									<FcDonate className="ct-icon" style={{ fontSize: "40px" }} />
									<div className="txt">
										{" "}
										<span className="txt-1">Make Donation</span>
										<span className="txt-2">
											We are open to your gifts in cash and kind for our
											projects and events. To make your donation, reach out to
											us via  
											<a href="mailto:teensliteracyfoundation@gmail.com">
												teensliteracyfoundation@gmail.com{" "}
											</a>{" "}
											or contact us at +2349031387197
										</span>
									</div>
								</section>

								{/*  */}
							</article>
							<article className="vol-section">
								{/*  */}
								<section className="ctx">
									<BiDonateHeart
										className="ct-icon"
										style={{ fontSize: "40px" }}
									/>
									<div className="txt">
										<span className="txt-1">Fund our Programmes</span>
										<span className="txt-2">
											Our projects require funds to actualize the specific goals
											of each of them. Your fund (monthly, quarterly, annually,
											one-off) will go a long way to cater for gifts,
											incentives, rewards, digital devices, and health
											materials.
										</span>
									</div>
								</section>
								{/*  */}
								<section className="ctx">
									{" "}
									<SiGooglescholar
										className="ct-icon"
										style={{ fontSize: "40px" }}
									/>
									<div className="txt">
										{" "}
										<span className="txt-1">Give Scholarship</span>
										<span className="txt-2">
											At Teens Literacy Foundation, we support and encourage all
											forms of education, and it is our firm resolution that
											access to quality education is a fundamental right to all.
											Our scholarship opportunities come through two schemes:
											<ol>
												<li>Adopt a child </li>
												<li>Adopt a school </li>
											</ol>
											You can write us: at  
											<a href="mailto:teensliteracyfoundation@gmail.com">
												{" "}
												teensliteracyfoundation@gmail.com{" "}
											</a>{" "}
											 
										</span>
									</div>
								</section>
								{/*  */}

								{/*  */}
							</article>
						</article>
						{/*  */}
						<div className="image-sec">
							<img src={vimage} alt="" />
						</div>
					</section>

					<article className="vol-footer">
						<div
							className="vf-desc1"
							onClick={() => {
								setDonations(true);
								setTimeout(() => {
									setDonations(false);
								}, 5000);
							}}>
							<span style={{ fontSize: "15px" }}>Give Donation</span>
							<span>
								We are open to your gifts in cash and kind for our projects and
								events
							</span>
							<strong className="no-1">.01</strong>
						</div>
						<div
							className="vf-desc2"
							onClick={() => {
								setDonations(true);

								setTimeout(() => {
									setDonations(false);
								}, 5000);
							}}>
							<span style={{ fontSize: "15px" }}>Give Scholarship</span>
							<span>
								Our scholarship opportunities come through two schemes:
								{/* <ol> */}
								<li style={{ listStyle: "none" }}>Adopt a child </li>
								<li style={{ listStyle: "none" }}>Adopt a school </li>
								{/* </ol> */}
							</span>
							<strong className="no-2">.02</strong>
						</div>
						<div
							className="vf-desc3"
							onClick={() => {
								setDonations(true);
								setTimeout(() => {
									setDonations(false);
								}, 5000);
							}}>
							<span style={{ fontSize: "15px" }}>Fund our programmes</span>
							<span>
								Our projects require funds to actualize the specific goals of
								each of them. Your fund (monthly, quarterly, annually, one-off)
								will go a long way to cater for gifts, incentives, rewards,
								digital devices, and health materials{" "}
							</span>
							<strong className="no-1">.03</strong>
						</div>{" "}
					</article>
				</section>

				<section className="donation-page">{donations && <Donate />}</section>
				{/* <Footer /> */}
			</EventContainer>
		</>
	);
};

const EventContainer = styled.section`
	.sn-button {
		background-color: rgb(217, 74, 57);
		color: white;
		border: 1px solid rgb(217, 74, 57);
		padding: 6px;
		border-radius: 2px;
	}
	.vol-head {
		// margin-block-start:50px;
		background: url(${vol});

		background-position: center center;
		background-repeat: no-repeat;
		height: 50vh;

		background-size: cover;

		box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.7);
		text-align: center;
		.vol-text {
			/* filter:blur(0.2): */
			display: flex;
			justify-content: center;
			flex-direction: column;
			gap: 8px;
			padding-block: 5%;
			align-items: center;
			padding-inline: 20%;
		}
		.vol-text p {
			color: white;
			font-weight: 100;
			padding-inline: 10px;
			font-size: 13px;
		}
		.vol-text input {
			padding: 6px;
			width: 30vw;

			background: transparent;
			outline: none;
			border: 1px solid #c8c3c3;
			border-radius: 4px #fff;
			/* box-shadow:; */
			/* font-size:5px; */
		}

		.vol-text::placeholder {
			color: red;
			font-size: 10px;
		}
		.vol-text button {
			background-color: #d94a39;
			color: white;
			border: 1px solid #d94a39;
			padding: 6px;
			border-radius: 2px;
		}
		.v-call {
			color: #fff;
			font-size: 18px;
			/* background:#D94A39; */
			border-bottom: 1px solid #f1f1f1;
			border-radius: 4px;
			font-weight: 500;
			outline: none;

			padding-inline: 6px;
			margin-block: 20px;
		}

		.ct-icon {
			font-size: 18px;
		}

		@media screen and (max-width: 40em) {
			// .vol-head {
			// 	padding-block: 30px;
			// }
			.vol-text p {
				color: white;
				font-weight: 100;
				line-height: 1.6em;
				padding-inline: 2px;
				font-size: 12px;
			}
			.vol-text input {
				padding: 6px;
				width: 60vw;

				background: transparent;
				outline: none;
				border: 1px solid #c8c3c3;
				border-radius: 4px #fff;
				/* box-shadow:; */
				/* font-size:5px; */
			}

			.vol-text {
				/* filter:blur(0.2): */
				display: flex;
				justify-content: center;
				flex-direction: column;
				gap: 8px;
				padding-block: 29%;
				align-items: center;
				align-self: center;
				padding-inline: 8%;
			}
		}
	}
`;

export default VolunteerPage;
