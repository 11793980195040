import React from "react";
import Header from "./Header/Header";
import student from "./img/misn.jpg";
// import DisplaySlider from "./Slider/DisplaySlider";
import president from "./img/president.jpg";
import Register from "./Register/Register";
import "./home.css";
// import Footer from "./Footer/Footer";
// import Events from "./Pages/VolunteerPage"
import Volunteer from "./Pages/Volunteer";
// import Donate from "./Pages/Donate";
import About from "./About/About";
import Event from "./Pages/Event";
import Image from "./Image/Image";
import "./About/about.css";
import "aos/dist/aos.css";
import AOS from "aos";
// import AnnouncementBanner from "./Announcement/AnnouncementBar";
// import VolunteerPage from "./Pages/VolunteerPage";
// import About from "./About/About";

const Homepage = () => {
	AOS.init();
	return (
		<div style={{ position: "relative" }}>
			{/* <div style={{ position: "absolute", background: "red" }}>
				<AnnouncementBanner />
			</div> */}
			<Header />
			<br />
			<br />
			<section className="call">
				<div className="t-desc">
					<h1 data-aos="zoom-in">What we do and how we do it</h1>
					<p data-aos="fade-up-right" data-aos-duration="3000">
						Our organization is primarily a Community Education, Intervention,
						and Development group. We create awareness through campaigns,
						lectures, student conferences, competition community workshops, and
						talk shows to provide the public with information on issues of our
						interest. These campaigns are expected to provide adequate
						knowledge, change attitudes, and promote lifestyle behavioral
						changes through practices. In addition, our organization conducts
						extensive research and survey to gather relevant data which could
						serve as input to solution development by all stakeholders. Our
						research methodology involves a significant percentage of collating
						primary data from the fields in the communities. Therefore, we
						interview teenagers, youths, teachers, parents, relevant
						institutions, community organizations, and all stakeholders. In
						addition, we carry out intervention programs as our contribution to
						the solutions developed. Such intervention programs include but are
						not limited to physical activities, competitions, sports, and games
						for health, with prizes to be won. We also involve relevant
						stakeholders and partner with other organizations, corporate bodies
						and individuals to achieve these goals to ensure the services and
						products for impacting the community lifestyle towards
						nation-building. For effectiveness, we set up communities, groups,
						and clubs in various schools and build inclusive and sustainable
						relationships between our organization and school administrators. We
						also measure our impact through other feedback mechanisms, such as
						reports from school management and administrators, parents,
						teachers, and community members where the influence of our
						organization and programs has spread to.
					</p>
				</div>

				<div className="image-desc">
					{/* <DisplaySlider/> */}
					<img
						src={student}
						alt=""
						data-aos="fade-down"
						data-aos-easing="linear"
						data-aos-duration="3000"
					/>
				</div>
			</section>
			<Volunteer />
			<br />
			<br />
			{/* About */}
			<About />
			{/* About */}
			<br />
			<br />
			<Event />
			<br />
			<br />
			{/* president's corner */}
			<section className="call" style={{ position: "relative", zIndex: "-1" }}>
				<div className="t-desc">
					<h2 data-aos="zoom-in-left" data-aos-duration="3000">
						The President's Corner
					</h2>
					<p
						style={{
							fontWeight: "400",
							lineHeight: "2.4em",
							textAlign: "justify",
						}}
						data-aos="fade-up-right"
						data-aos-duration="3000">
						I founded Teens Literacy Foundation (TLF) in 2022, even though the
						organization started as the Build Them Initiative (BTI) in 2019. I
						was raised in a Yoruba community in Nigeria where the locals
						believed in collective responsibility in raising a child. My
						community also strictly monitors male children as they believe they
						are to model a morally upright society. Growing up as the only male
						child in a family of five with two elder sisters, I shared a room
						with my sisters, which helped shape me to have a balanced
						orientation about the girl-child. One of the gifts I enjoyed while
						growing up was my family and peaceful society even though the
						society had flaws. <br></br>
						During my third year in college studying Pharmacy, I started
						volunteering for NGOs and by the following year, I got my first
						invitation to be a speaker to address high school students on
						diversity and inclusion issues concerning career choices. I
						effectively engaged my audience by sharing my story about the
						community I came out from, the similar peculiar issues I faced, and
						my resolve from the experiences that made me who I am. My story and
						oratory prowess allowed me and my audience to connect better, and by
						the end of the lecture, a lot had a better grasp on how to be in
						charge of their life regardless of their chosen career path.{" "}
						<br></br>I have been involved in volunteering activities ever since.
						I took special interest in research, Health Promotion, and education
						of vulnerable populations, especially concerning preventive health
						and precision medicine, people development, and policy development
						and execution around education. With an extraordinary passion for
						young people, I believe educating them; parents, teachers, and
						stakeholders provide a pathway for achieving sustainable development
						goals. I have served in various leadership positions up to the
						post-college level and have volunteering experience with multiple
						organizations. All these culminated to starting an NGO that focuses
						on teens' literacy and a holistic approach toward education through
						education programs beyond the walls of the classroom. I have been
						actively promoting and advocating for access to quality education to
						vulnerable groups such as children living on the streets, in slums
						and ghettos, orphans, children of the less-privileged in society,
						and children in semi-rural and rural communities.<br></br>I am proud
						of my upbringing in my small community in Africa. My black ancestry
						has allowed me to be tough in my approach to life, and my
						volunteering activities have afforded me the opportunity to shape
						the outlook of people I meet. My plans include building the
						organization I lead to become a global player in advocacy, people
						development, and program execution in line with Sustainable
						Development Goals. My name is Isaac Oluwadamilare ONI; my vision is
						clear: Building an Army of Leaders across different sectors with the
						right education tool!
					</p>
				</div>
				<div className="p-image">
					<img
						src={president}
						alt=""
						data-aos="flip-left"
						data-aos-easing="ease-in-cubic"
						data-aos-duration="2000"
					/>
				</div>
			</section>
			<Image />
			<Register />
			{/* <Footer /> */}
		</div>
	);
};

export default Homepage;
