// import React, { useState } from "react";
// import Announcement from "./Announcement";
// import Announcement from "./Ann";
import "../Announcement/announcement.css";
const AnnouncementBanner = () => {
	// const [isVisible, setIsVisible] = useState(true);

	// const handleClose = () => {
	// 	setIsVisible(false);
	// };

	return (
		<section className="ann">
			<div className="ann-text">Upcoming event: "Shoe a child"</div>
			<a href="#events">
				<button className="ann-btn">Learn more</button>
			</a>
		</section>
	);
};

export default AnnouncementBanner;
