import React from "react";
import styled from "styled-components";
import "./donate.css";
// import Shared from '../Outlet/Shared'
// import Footer from "../Footer/Footer";
// import Navb from '../Navbar/Navbar'
import donate from "../img/WhatsApp Image 2023-09-15 at 14.09.04.jpeg";
// import GenNav from "../GenNav";
// import Nav from '../Nav'

const Donate = () => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}>
			{/* <GenNav/> */}
			<Donation className="donation">
				<div className="donate-sec">
					<img src={donate} alt="" />
				</div>

				{/* <span className='qtn' href="https://">
            
            <div className='radio'><input type="checkbox"/> once</div>
            <div className='option'><input type="checkbox"/> recurring</div>
            
            
            </span> */}
				{/* 
            <section href="/#" className='selection'>

            
<article className="article-one">
<label> I want to Donate</label>
   
   <select>
       <option> </option>
       <option>Food </option>
       <option>Dress </option>
       <option> Toys</option>
       <option>Money</option>
       <option> Education</option>
   </select>
  
</article>
<article className="article-two">
<label>Currency</label>
   
   <select>
     <option> </option>
       <option> Usd</option>
       <option> NGN</option>
   </select>
  
</article>

<article className="article-three">
    <label>How much do you want to Donate</label>
 <input className='donate-ipt'/>
    
 
</article>
<button className='d-btn'>Donate Now</button>
</section> */}
			</Donation>
			{/* <Footer className="foot" /> */}
			{/* </Shared> */}
			<br></br>
		</div>
	);
};
const Donation = styled.section`
	@media screen and (min-width: 40em) {
		.donate-sec {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 550px;
		}
	}
	.donate-sec img {
		height: 100%;
		// align-self:center;
		width: 100%;
	}
	.donation {
		background: #333333;
	}

	.qtn {
		display: flex;

		align-items: center;
		gap: 20px;
	}
	.selection {
		display: flex;
		gap: 2px;
		align-items: center;
	}
`;
export default Donate;
