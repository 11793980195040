import React from 'react'
import "./event.css"
// import vol from "../img/vll.jpg"
import v1 from "../img/pstoutrch.jpg"
import v2 from "../img/outreach.jpg"
import v3 from "../img/outrch.jpg"
// import vimage from '../img/volun.jpg'
import "aos/dist/aos.css";
import AOS from "aos";
import shoe from '../img/shoeachild.jpeg'

const Event = () => {
        AOS.init();
  return (
    <div >

    <article className='vol-img'  style={{position:'relative', zIndex:'-1'}}>
        <a href='/' className='vol-img_header'> 
        <p>What we do</p>
        <div className="divider"></div> </a>
        <div className="vol-image">
            <img src={v1} alt="" data-aos="zoom-out-left"  data-aos-duration="3000"/>
        <img src={v2} alt="" className='img-v2' data-aos="zoom-in" data-aos-duration="2500"/>
        <img src={v3} alt="" data-aos="zoom-out-right"  data-aos-duration="3000"/>
        </div>
    </article>
    {/*  */}
    <div className='event-head' id='events'>
<h1 className='event-title'> Upcoming Events</h1>
    <div className="divider"></div>
    <h3>2024</h3>   
    </div>

    {/* <section className='event-section'> */}
    <section>
    {/* <article className="event">
    
        
        <div className="event-description">
            <h4>TWALP School Outreach</h4>
            <p className="description">
                TWALP School Outreach

Venue: Ajagunla High School, Ila-Orangun

Date: October 11, 2023.</p>
         
            </div>

    </article> */}

    {/*  */}
    <article className="event">
        <div className="event-description">
            <h4>Shoe a Child</h4>
            <p className="description"> Shoe a Child (Target: 100 pupils across 2 Local Government in Osun State)
The Ila local government and the Obokun local government are both greatly underserved regions in Osun State. These communities are located many miles away from the urban regions, thereby needing more exposure and opportunities found in the urban areas. Their occupation is primarily farming. The families in this region are majorly polygamous, of which the fathers are peasant farmers and leverage the members of their families to carry out farm laborious activities.  As civilization gradually worked its way into their communities, basic infrastructures, including primary schools, were introduced to improve the lives of the community dwellers. 

However, most children in these communities still need to be educated. It was discovered that although the schools had facilities and qualified teachers, the to-be pupils needed more equipment for schooling, including the lack of footwear. This has posed a factor, causing apathy in the pupils towards schooling, and it has been observed that there are several out-of-school children in their communities. Some have contracted several diseases such as podoconiosis, plantar warts, tetanus, and several others due to walking several distances to school barefoot. 

To alleviate this, as an organization, we look forward to providing footwear to 200-250 school pupils. We thereby call to the public, individuals (local and international), and corporate bodies to support our mission. You can give in cash or donate footwear. Other details can be found on image.

.</p>
        {/* <a href="/" className="event-timing"> 10:am</a> */}
        </div>

<img src={shoe} alt='' className='ann-img'/>
    </article>
    {/*  */}
    </section>
</div>
  )
}

export default Event