import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
// import { MdHeight } from 'react-icons/md'

const Courses = () => {
  return (
    <CoursesWrapper>
    <div className="overlay">
      <div className="white" > 
      Coming Soon
        <Link to="/"> <button style={{ background:"#28D1ff", color:"white", borderRadius:"8px",padding:"10px 30px", border:"1px solid #28d1ff" }}>Back</button></Link>
      </div>
    </div>
     </CoursesWrapper>
  )
}

const CoursesWrapper = styled.div `

// .overlay{
//   background-color: rgba(0, 0, 0, 0.425);
// position: fixed;
// display: flex;
// flex-direction: column;
// justify-content: center;
// align-items: center;
// width: 100%;
// height: 100%;

// }
// .white{
//   background:white;
//  width:30%; 
//  height:50%;
//  border-radius:6px;
//   display:flex;
//    flex-direction:column;
//    gap:20px; 
//    justifyContent:center;
//     align-items:center;
//     fontSize:60px;
//      color:Red;
// }

@media screen and (max-width:40em){
    .overlay{
background:rgba(0,0,0,0.2);
 height:100%;
   width:100%;
    position:fixed;
     display:flex; 
     justify-content:center;
    //  alignItems:center;
     align-items: center;
    }
    .white{
background: white;
    width: 76%;
    height: 40vh;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-size: 20px;
    color: red;
    }
  }

 @media screen and (min-width:40em){
.overlay{
background:rgba(0,0,0,0.2);
 height:100%;
 width:100%;
  position:fixed;
  display:flex;
   justify-content:center; 
   align-items:center;
    }
.white{
background:white;
 width:50%; 
 height:50%;
 border-radius:6px;
  display:flex;
   flex-direction:column;
   gap:20px; 
   justify-content:center;
    align-items:center;
    font-size:60px;
     color:Red;
    }
  }

`
  

export default Courses;
