import React, { useState, useRef  } from "react";
import "./form.css";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
// import React, { } from 'react';
import emailjs from '@emailjs/browser';
// import "react-phone-number-input/style.css";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { isToastIdValid } from "react-toastify/dist/utils";
// import PhoneInput from "react-phone-number-input";
// toast.configure({
//   bodyClassName: {
//     backgroundColor: "blue",
//     height: "500px",
//     width: "100%",
//   },
// });
function Form() {
  const [firstName, setfirstName] = useState("");
  // const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  // const [phoneNumber, setphoneNumber] = useState("");

  const [additionalInformation, setadditionalInformation] = useState("");
 
  const [isLoading, setisLoading] = useState(false);

  

  const form = useRef();

  const sendEmail = (e) => {
    
    setisLoading(true);
    e.preventDefault();
//  details();

    emailjs.sendForm('service_jcwsreh', 'template_k3djsmq', form.current, 'yM3x5AzUSRJqdyURM')
      .then((result) => {
      
          setisLoading(false);
          console.log(result.text);
          toast.success('Response successfully Sent')   
      },
      
      (error) => {
        console.log(error.text);
           toast.error('Submit Unsuccessful');
           setisLoading(false)
      });
  };
  
//   const handler = (e) => {
//     e.preventDefault();

// setEmail(e.target.value);
// setfirstName(e.target.value)
//   setadditionalInformation(e.target.value);

//   };

  const additionalInformationHandler = (e) => {
    setadditionalInformation(e.target.value);
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <section className="forms">
          <div className="names">
            <li className="form-details">
              <label>
                Name <sup>*</sup>{" "}
              </label>{" "}
              <input
                required
                placeholder="Johnxxxxxx"
                type="text"
                value={firstName}
                onChange={(e) => setfirstName(e.target.value)}
name="user_name"
                className="name-input"
                
              />
            </li>

            {/* <li className="form-details">
              <label>
     .form-details           LastName<sup>*</sup>
              </label>
              <input
                required
                placeholder="Doe"
                type="text"
                onChange={(e) => setlastName(e.target.value)}
              />
            </li> */}
          </div>

          <div className="contact-details">
            <li className="form-details">
              <label for="email">
                Email Address<sup>*</sup>
              </label>
              <input
                required
                placeholder="johndoe@gmail.com"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="user_email"
              />
            </li>
            {/* <li className="form-details">
              <label for="phone" name='phone_number'>
                Phone Number<sup>*</sup>
              </label>
              <input
                // type="number"
                required
                placeholder="Enter phone number"
                value={phoneNumber}
                onInput={(e) => {
                  setphoneNumber(e.target.value);
                }}

                
              />
            </li> */}
          </div>

        
          <div className="form-details">
            <label>Message(Optional)</label>
            <textarea
              className="comment"
              value={additionalInformation}
              name="message"
              onChange={additionalInformationHandler}
            ></textarea>
          </div>
        </section>
        <button  className="btN" type="submit"  value="Send">
          {isLoading ? "processing..." : "Submit"}
        </button>
         <ToastContainer
            position="top-center"
            autoClose={100}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
      </form>
      {/* <ToastContainer
        limit={1}
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" */}
      {/* />{" "} */}
    </>
  );
}

export default Form;
