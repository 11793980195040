import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import styled from "styled-components";

import logo from "../img/tlf.jpeg";
import { Link, useLocation } from "react-router-dom";
import AnnouncementBanner from "../Announcement/AnnouncementBar";
// import AnnouncementBanner from "../Announcement/AnnouncementBar";
// import AnnouncementBanner from "../Announcement/AnnouncementBar";
// import Dropdown from "../WaitingPage/Dropdown";

const Anime = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	// Clean up the event listener when the component unmounts
	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
		if (!isMenuOpen) {
			// Disable scrolling when the navbar is opened
			document.body.style.overflow = "hidden";
		} else {
			// Enable scrolling when the navbar is closed
			document.body.style.overflow = "auto";
		}
	};
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		// Add an event listener to the window scroll event
		window.addEventListener("scroll", handleScroll);
		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);
	const [show, setShow] = useState(false);

	const handleClick = () => {
		setShow(!show);
	};
	const handleMenuClose = () => {
		setIsMenuOpen(false);
		document.body.style.overflow = "auto";
	};

	const handleScroll = () => {
		// Check the scroll position and update the 'scrolled' state accordingly
		if (window.scrollY > 100) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};
	const location = useLocation();
	useEffect(() => {
		// Get all the anchor elements in the navbar
		const links = document.querySelectorAll(".nav-list a");

		// Loop through the links and check if their href matches the current URL
		links.forEach((link) => {
			if (link.href === window.location.href) {
				// Add the 'active' class to the matching link
				link.classList.add("active");
			} else {
				// Remove the 'active' class from non-matching links
				link.classList.remove("active");
			}
		});
	}, [location]);

	return (
		<AnimeWrapper>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					background: "#0069ff",
					zIndex: "30",
					top: "0",
					width: "100vw",
					paddingBlock: "6px",
					// marginTop: "5px",
				}}>
				<AnnouncementBanner />
			</div>
			<article>
				<section className={`navbar ${scrolled ? "scrolled" : ""}`}>
					<div className="title">
						<img src={logo} alt="" />
						<span className="title-header">Teens Literacy Foundation</span>
					</div>
					<div className="menu-toggle" onClick={toggleMenu}>
						{isMenuOpen ? <FaTimes /> : <FaBars />}
					</div>
					<ul className={isMenuOpen ? "nav-list open" : "nav-list"}>
						<li className={isMenuOpen ? "animate" : "fade-out"}>
							<Link to="/" onClick={handleMenuClose}>
								Home
							</Link>
						</li>
						<li
							className={isMenuOpen ? "animate" : "fade-out"}
							onClick={handleClick}
							onMouseEnter={handleClick}>
							<Link to="initiatives" onClick={handleMenuClose}>
								Initiatives & Programmes
							</Link>
						</li>
						<li className={isMenuOpen ? "animate" : "fade-out"}>
							<Link to="volunteer" onClick={handleMenuClose}>
								Get Involved
							</Link>
						</li>
						<li className={isMenuOpen ? "animate" : "fade-out"}>
							<Link to="Donate" onClick={handleMenuClose}>
								Donate
							</Link>
						</li>

						<li className={isMenuOpen ? "animate" : "fade-out"}>
							<Link to="https://teensliteracyfoundation.blogspot.com/">
								Blog
							</Link>
						</li>
						<li className={isMenuOpen ? "animate" : "fade-out"}>
							<Link to="/courses" onClick={handleMenuClose}>
								Courses
							</Link>
						</li>
					</ul>
				</section>
			</article>
		</AnimeWrapper>
	);
};
const AnimeWrapper = styled.div`
position:relative;
display:flex;
flex-direction:column;
// margin-bottom:40px;
// gap:30px;
.navbar{
    // background:#f5f5f5;
    background: rgba(228, 227, 227, 0.1);
    // color:#fff;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:50px 10px;
    transition:all 0.3s ease;
     position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height:7.5vh;
z-index:3;
}
.title{
    display:flex;
    align-items:center;
    gap:20px;
    margin-top:40px;
}
.nav-list{
    margin-right:6rem;
    display:flex;
    align-items:center;
    gap:4rem;
    color:#425CE8;
    margin-top:40px;
}
.title-header{
    font-weight:bold;
    // color:#43edb4;
     color:#61d0ff;
}
.title img{
 height: 6vh;
    border-radius: 50%;
}
.navbar.scrolled {
    //  background: #bc4040ce;
    background:rgb(41 51 92 / 88%);
        // background: rgb(41, 51, 92);
    // background: #c5c5c5a1;
}
.nav-list a{
    text-decoration:none;
    color:#61d0ff;
    // display:flex;
}
    li{
        list-style:none;
    margin:0 10px;
}

}
.a_sign-up_btn{
 background:#425CE8;
    color:#fff;
     border:none;
    // border:1px soild #425CE8;
    border-radius:8px;
    padding:10px 20px;
} 
.a_sign-in_btn{
background:#f5f5f5;
color:#425CE8;
 border:1px solid #425CE8;
 border-radius:8px;
 padding:10px 20px;
}
.dropdown {
  position: relative;
  cursor: pointer;
//   padding-inline:22px;
  // display:flex;
  // align-items:center;
  gap:10px;
  font-family: 'Lato';
  
}
.navbar a:hover{
     border-bottom: 1.3px solid #ff0000;
    // border-bottom: 1.5px solid #f18c8c;
    border-radius: 8px;
    font-weight: bold;
     color:#000000;
}

  .nav-list a{
    text-decoration:none;
    // color:#61d0ff;
    color:#8fe6f1;
    // color:#43edb4;
    font-weight:bold;
    // display:flex;
}
 
.a-cta{
  display:flex;
  gap:60px;
}
.menu-toggle{
    cursor:pointer;
    display:none;
    flex-direction:columnn;
    margin-right:2rem;
}

// .navbar a {
//   color: #333;
//   text-decoration: none;
// }

/* Active link styles */
.navbar a.active {
  color: #ff0000; /* Change to your desired color */
  font-weight: bold; /* Apply any other styles you want */
}

@media (max-width:40em){
{
    .navbar{
        // color:#43edb4;
        padding:6px;
        color:#61d0ff;
    }
}
.navbar a:hover{
     border-bottom: 1.3px solid #ff0000;
    // border-bottom: 1.5px solid #f18c8c;
    border-radius: 8px;
    font-weight: bold;
     color:#61d0ff;
}
    .menu-toggle{
        display:flex;
             transition: max-height 0.6s ease-in, opacity 0.5s ease-in;
  animation: fadeInUp 0.6s ease-in-out forwards;
  margin-right:0.5rem;
    }
    .title-header{
        font-size:14px;
        // color:#43edb4;
        color:#61d0ff;
    font-weight:bold;
}
    .nav-list a{
    text-decoration:none;
    color:#fff;
}
.title{
    gap:10px;
    margin-top:0px;
}
.title img{
 height: 4vh;
    border-radius: 50%;
}
   .a-cta{
  display:flex;
  gap:60px;
  align-self:baseline;
}
    .nav-list{
     position:fixed;
     display: flex;
  flex-direction:column;
    top: 47px;
    left: 0;
    width: 100%;
    height: 100vh;
    gap: 4.5rem;
    align-items: baseline;
    padding-block:4rem;
    background-color: #000000;
animation: fade-out 0.5s ease forwards;
animation-delay:2s;
    padding-inline-start: 16px;
    opacity:0;
    visibility:hidden;

    }

.dropdown {
  position: relative;
  cursor: pointer;
//   padding-inline:22px;
  // display:flex;
  // align-items:center;
  gap:10px;
  font-family: 'Lato';
  
}
   .title{
    height: 4vh;
    border-radius: 50%;
}

@keyframes fade-out{
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
   
  }
}

@keyframes fadeInLeft{
    from{
        opacity:1
    }
    to{
        opacity:0;
    transfrom:translateX(-100%);
    }
}
.fade-out {
    -webkit-animation: fadeInLeft 1s linear;
  animation: fadeInLeft 4s ease-in-out ;
  opacity:0;
}
.fade-out:nth-child(5) {
  animation-delay: 0.5s;
}
.fade-out:nth-child(4) {
  animation-delay: 0.8s;
}

.fade-out:nth-child(3) {
  animation-delay: 1.2s;
}

.fade-out:nth-child(2) {
  animation-delay: 2.2s;
}

.fade-out:nth-child(1) {
  animation-delay: 3.0s;
}

     .nav-list.open{
      position: fixed;
    top: 59px;
    left: 0;
    width: 100vw;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    -webkit-box-align: baseline;
    align-items: baseline;
    padding-block: 4rem;
    gap: 4.5rem;
    opacity: 1;
    z-index: 1;
    animation: 1s ease-in 0s 1 normal none running slideInLeft;
    padding-inline-start: 16px;
    visibility: visible;
    overflow: hidden;
    margin:0;
     }

@keyframes slideInLeft{
  from{
    transform:translateX(-300px)
  }
  to{
    transform:translateX(0)
  }
}
    }
 
 .nav-list li.animate {
        opacity:1;
        transform:translateX(0);
        pointer-events:auto;
        transition:opacity 1s;
         transition-delay:5s;
     }
.animate {
  animation: fadeInUp 0.5s ease-in-out forwards running;
  opacity:1;
}
.animate:nth-child(1) {
  animation-delay: 0.5s;
}

.animate:nth-child(2) {
  animation-delay: .8s;
}

.animate:nth-child(3) {
  animation-delay: 1.3s;
}

.animate:nth-child(4) {
  animation-delay: 1.9s;
}
.animate:nth-child(5) {
  animation-delay: 2.5s;
}

 @keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.a_sign-up_btn{
 background:#425CE8;
    color:#fff;
     border:none;
    // border:1px soild #425CE8;
    border-radius:8px;
    // padding:10px 20px;
} 
.a_sign-in_btn{
background:transparent;
    color:#425CE8;
    border:1px solid #425CE8;
    border-radius:8px;
//    padding:10px 20px;
}

}

`;
export default Anime;
