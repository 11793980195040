import React from "react";
import Register from "./Register/Register";
// import Footer from './Footer/Footer'
// import GenNav from './GenNav'

const Contact = () => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				gap: "60px",
			}}>
			{/* <GenNav/> */}
			<Register />
			{/* <Footer/> */}
		</div>
	);
};

export default Contact;
